<script setup>
import {ref} from "vue";
import moment from "moment";
import {useAppStore} from "../../../../../store/app";
import JsonViewer from 'vue-json-viewer'
import 'vue-json-viewer/style.css'
import {usePayoutsStore} from "../../../../../store/Payouts/payouts";

const props = defineProps(['orderpayouts', 'html'])

const app = useAppStore();
const payoutsStore = usePayoutsStore();

payoutsStore.setPayouts(props.orderpayouts?.data ?? []);
const payouts = payoutsStore.payouts;

const dateFormat = (date) => {
    return moment(date).format('LLL');
};
const selectedOrder = ref([]);
const setSelectedOrder = (order) => {
    selectedOrder.value = order;
    payoutsStore.setSelectedPayout(order)
}

const approvingPayout = ref([]);
const approvePayout = (index, payout) => {
    approvingPayout.value[index] = true;
    axios.post(`/apis/v4/admin/approvePayout/${payout.id}`)
        .then(resp => {
            approvingPayout.value[index] = false;

        }).catch(() => {
        approvingPayout.value[index] = false;
    });
}
const errors = ref('');
const select = ref('');
const Order = ref('');

const searchByFilters = () => {
    const currentUrl = window.location.href;
    if (select.value != undefined || select.value != '') {
        let urlObj = new URL(window.location.href);

        if (urlObj.searchParams.has('status')) {
            urlObj.searchParams.delete('status');
        }
        if (urlObj.searchParams.has('order')) {
            urlObj.searchParams.delete('order');
        }
        if (!urlObj.searchParams.has('status')) {
            urlObj.searchParams.append('status', select.value);
        }
        if (!urlObj.searchParams.has('order')) {
            urlObj.searchParams.append('order', Order.value);
        }

        window.location.href = `${urlObj.toString()}`;
    }
}
</script>

<template>
    <div>
        <div class="row mb-3">
            <div class="col-md-9">
                <div class="row">
<!--                    <div class="col-md-3">-->
<!--                        &lt;!&ndash;Label: Filter by Date, Attributes:filter_by_date &ndash;&gt;-->
<!--                        <div class="form-floating">-->
<!--                            <input type="date" class="form-control" id="filter_by_date" v-model="filter_by_date"-->
<!--                                   aria-describedby="filter_by_date-help"-->
<!--                                   :class="[errors.filter_by_date ? 'is-invalid': '',!errors.filter_by_date && Object.keys(errors).length > 1 ? 'is-valid': '']"-->
<!--                                   placeholder="Filter by Date" required>-->
<!--                            <label for="filter_by_date">Filter by Date</label>-->
<!--                            <div class="invalid-feedback" v-if="errors.filter_by_date">-->
<!--                                {{ errors.filter_by_date.toString() }}-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="col-md-3 tw-mt-3 md:tw-mt-0">-->
<!--                        &lt;!&ndash;Label: Order, Attributes:Order &ndash;&gt;-->
<!--                        <div class="form-floating">-->
<!--                            <input type="text" class="form-control" id="Order" v-model="Order"-->
<!--                                   aria-describedby="Order-help"-->
<!--                                   :class="[errors.Order ? 'is-invalid': '',!errors.Order && Object.keys(errors).length > 1 ? 'is-valid': '']"-->
<!--                            >-->
<!--                            <label for="Order">Filter by Order ID</label>-->
<!--                            <div class="invalid-feedback" v-if="errors.Order">-->
<!--                                {{ errors.Order.toString() }}-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
                    <div class="col-md-3 tw-mt-3 md:tw-mt-0">
                        <!--Label: Select, Attributes:select -->
                        <div class="form-floating">
                            <select type="text" class="form-control" id="select" v-model="select"
                                    aria-describedby="select-help"
                                    :class="[errors.select ? 'is-invalid': '',!errors.select && Object.keys(errors).length > 1 ? 'is-valid': '']"
                            >
                                <option value="" disabled>-- Choose Status --</option>
                                <option value="success">Success</option>
                                <option value="failed">Failed</option>
                                <option value="in_transit">In Transit</option>
                            </select>
                            <label for="select">Filter by Status</label>
                            <div class="invalid-feedback" v-if="errors.select">
                                {{ errors.select.toString() }}
                            </div>
                        </div>
                    </div>
<!--                    <div class="col-md-3 tw-mt-3 md:tw-mt-0">-->
<!--                        &lt;!&ndash;Label: Filter by Transaction ID, Attributes:transactionId &ndash;&gt;-->
<!--                        <div class="form-floating">-->
<!--                            <input type="text" class="form-control" id="transactionId" v-model="transactionId"-->
<!--                                   aria-describedby="transactionId-help"-->
<!--                                   :class="[errors.transactionId ? 'is-invalid': '',!errors.transactionId && Object.keys(errors).length > 1 ? 'is-valid': '']"-->
<!--                            >-->
<!--                            <label for="transactionId">Filter by Transaction ID</label>-->
<!--                            <div class="invalid-feedback" v-if="errors.transactionId">-->
<!--                                {{ errors.transactionId.toString() }}-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
                </div>
            </div>
            <div class="col-md-2 mt-2">
                <button class="btn btn-outline-secondary" @click.prevent="searchByFilters">
                    Filter
                    <i class="fal fa-filter"></i>
                </button>
            </div>
        </div>
        <div class="table-responsive">
            <table class="table table-striped table-sm table-bordered">
                <thead class="thead-default">
                <tr>
                    <th>#</th>
                    <th>Date</th>
                    <th>Order ID</th>
                    <th>Status</th>
                    <th>Transaction Reference</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(order,index) in payouts">
                    <td>{{ order.id }}</td>
                    <td>{{ dateFormat(order.created_at) }}</td>
                    <td>
                        <a target="_blank" :href="`/four/admin/orders/${order.additionalData?.order[0]?.id}`"
                           class="tw-font-bold">
                            {{ order.additionalData?.order[0]?.order_id }}
                        </a>
                    </td>
                    <td>
                        <div class="badge bg-danger text-white"
                             v-if="order.status == 'failed' || order.status == 'canceled'">
                            {{ order.status?.toUpperCase() }}
                        </div>
                        <div class="badge bg-warning text-white" v-else-if="order.status == 'pending'">
                            {{ order.status?.toUpperCase() }}
                        </div>
                        <div class="badge bg-warning text-white" v-else-if="order.status == 'in_transit'">
                            IN TRANSIT
                        </div>
                        <div class="badge bg-success text-white"
                             v-else-if="order.status == 'success'||order.status == 'paid'">
                            {{ order.status?.toUpperCase() }}
                        </div>
                        <div class="badge bg-secondary text-white" v-else>
                            {{ order.status?.toUpperCase() }}
                        </div>
                    </td>
                    <td>
                        <span class="text-muted">
                            {{ order.additionalData?.transactionReference }}
                        </span>
                    </td>
                    <td>
                        <button class="btn btn-outline-secondary btn-sm tw-ml-3" data-bs-toggle="offcanvas"
                                data-bs-target="#viewPayoutDetails"
                                @click="setSelectedOrder(order)"
                                aria-controls="viewPayoutDetails">
                            View
                            <i class="fa-solid fa-eye"></i>
                        </button>

                        <template v-if="order.status == 'pending'">
                            <!--                            <button class="btn btn-outline-danger btn-sm tw-ml-3" :disabled="approvingPayout[index]">-->
                            <!--                                Reject-->
                            <!--                                <i class="fa-solid fa-xmark"></i>-->
                            <!--                            </button>-->
                            <button class="btn btn-outline-success btn-sm tw-ml-3"
                                    @click.prevent="approvePayout(index,order)" :disabled="approvingPayout[index]">
                                Approve
                                <i class="fa-solid fa-spin fa-spinner" v-if="approvingPayout[index]"></i>
                                <i class="fa-solid fa-circle-check" v-else></i>
                            </button>
                        </template>

                        <template v-if="order.status == 'failed'">
                            <button class="btn btn-outline-warning btn-sm tw-ml-3"
                                    @click.prevent="approvePayout(index,order)" :disabled="approvingPayout[index]">
                                Retry
                                <i class="fa-solid fa-spin fa-spinner" v-if="approvingPayout[index]"></i>
                                <i class="fa-solid fa-arrow-rotate-right" v-else></i>
                            </button>
                        </template>

                    </td>
                </tr>
                </tbody>
            </table>
            <div v-html="html">
            </div>
            <p class="text-center">
                Showing {{ orderpayouts.from }} to {{ orderpayouts.to }} of
                {{ orderpayouts.total }} payouts
            </p>
        </div>
    </div>

    <div class="offcanvas offcanvas-end w-75" tabindex="-1" id="viewPayoutDetails"
         aria-labelledby="viewPayoutDetailsLabel">
        <div class="offcanvas-header">
            <h5 id="viewPayoutDetailsLabel">Payout Details</h5>
            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
            <div class="card">
                <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                        <dl class="row">
                            <dt class="col">Status</dt>
                            <dd class="col">
                                <div class="badge bg-danger text-white"
                                     v-if="selectedOrder.status == 'failed' || selectedOrder.status == 'canceled'">
                                    {{ selectedOrder.status?.toUpperCase() }}
                                </div>
                                <div class="badge bg-warning text-white" v-else-if="selectedOrder.status == 'pending'">
                                    PENDING
                                </div>
                                <div class="badge bg-warning text-white"
                                     v-else-if="selectedOrder.status == 'in_transit'">
                                    IN TRANSIT
                                </div>
                                <div class="badge bg-success text-white"
                                     v-else-if="selectedOrder.status == 'success'||selectedOrder.status == 'paid'">
                                    {{ selectedOrder.status?.toUpperCase() }}
                                </div>
                                <div class="badge bg-secondary text-white" v-else>
                                    {{ selectedOrder.status?.toUpperCase() }}

                                </div>
                            </dd>
                        </dl>
                    </li>
                    <li class="list-group-item">
                        <dl class="row">
                            <dt class="col">Created At:</dt>
                            <dd class="col">
                                {{ dateFormat(selectedOrder.created_at) }}
                            </dd>
                        </dl>
                    </li>
                    <li class="list-group-item">
                        <dl class="row">
                            <dt class="col">Amount:</dt>
                            <dd class="col">
                                {{ app.currencySign }} {{ selectedOrder.amount }}
                            </dd>
                        </dl>
                    </li>
                    <li class="list-group-item">
                        <dl class="row">
                            <dt class="col">Destination Name:</dt>
                            <dd class="col">
                                {{ selectedOrder.destination?.payout_item?.receiver?.name }}
                            </dd>
                        </dl>
                    </li>
                    <li class="list-group-item">
                        <dl class="row">
                            <dt class="col">Destination Account:</dt>
                            <dd class="col">
                                {{ selectedOrder.destination?.payout_item?.receiver?.account }}
                            </dd>
                        </dl>
                    </li>
                    <li class="list-group-item" v-if="payoutsStore.selectedPayout?.mobileMoneyInfo">
                        <dl class="row">
                            <dt class="col">Transaction Information</dt>
                            <dd class="col">
                                <div>
                                    <span class="tw-font-extrabold small tw-uppercase"> {{
                                            payoutsStore.selectedPayout?.mobileMoneyInfo?.WalletType
                                        }}</span>
                                </div>
                                <div>
                                    {{ payoutsStore.selectedPayout?.mobileMoneyInfo.ReceiverName }}
                                </div>
                                <div>
                                    {{ payoutsStore.selectedPayout?.mobileMoneyInfo.ReceiverMsisdn }}
                                </div>
                                <div>
                                    {{ payoutsStore.selectedPayout?.mobileMoneyInfo.ThirdPartyTranID }}
                                </div>

                            </dd>
                        </dl>
                    </li>
                </ul>
            </div>
            <div class="card">
                <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                        <dl class="row">
                            <dt class="col">Seller</dt>
                            <dd class="col">
                                <div>
                                    {{ payoutsStore.selectedPayout?.seller?.name }}
                                </div>
                                <div>
                                    <small class="text-muted tw-font-semibold">{{
                                            payoutsStore.selectedPayout?.order?.seller_group?.name
                                        }}</small>
                                </div>
                                <div>
                                    <small class="text-muted tw-font-semibold">{{
                                            payoutsStore.selectedPayout?.seller?.phone
                                        }}</small>
                                </div>
                            </dd>
                        </dl>
                    </li>
                    <li class="list-group-item">
                        <dl class="row">
                            <dt class="col">Buyer</dt>
                            <dd class="col">
                                <div>
                                    {{ payoutsStore.selectedPayout?.buyer?.name }}
                                </div>
                                <div>
                                    <small class="text-muted tw-font-bold">{{
                                            payoutsStore.selectedPayout?.buyer?.phone
                                        }}</small>
                                </div>
                                <div>
                                    <small class="text-muted tw-font-semibold">{{
                                            payoutsStore.selectedPayout?.order?.buyer_group?.name
                                        }}</small>
                                </div>
                            </dd>
                        </dl>
                    </li>
                    <li class="list-group-item">
                        <dl class="row">
                            <dt class="col">Order Details</dt>
                            <dd class="col">
                                <div>
                                    {{ payoutsStore.selectedPayout?.order?.order_id }}

                                </div>
                                <div>
                                    <small class="text-muted tw-font-bold">{{
                                            payoutsStore.selectedPayout?.order?.summary_line
                                        }}</small>
                                </div>
                                <div>
                                    <small class="text-muted tw-font-bold">{{ app.currencySign }}{{
                                            payoutsStore.selectedPayout?.order?.items_price
                                        }}</small>
                                </div>
                            </dd>
                        </dl>
                    </li>

                </ul>
            </div>
            <dl class="row">
                <dt class="col-12">Destination:</dt>
                <dd class="col-12">
                    <json-viewer
                        :value="selectedOrder.destination"
                        :expand-depth=1
                        :preview-mode="false"
                        boxed
                        sort>

                    </json-viewer>
                </dd>
            </dl>
            <dl class="row">
                <dt class="col-12">Additional Data:</dt>
                <dd class="col-12">
                    <json-viewer
                        :value="selectedOrder?.additionalData"
                        :expand-depth=1
                        :preview-mode="false"
                        boxed
                        sort>

                    </json-viewer>
                </dd>
            </dl>
            <dl class="row">
                <dt class="col-12">MetaData:</dt>
                <dd class="col-12">
                    <json-viewer
                        :value="selectedOrder?.metadata"
                        :expand-depth=1
                        :preview-mode="false"
                        boxed
                        sort>

                    </json-viewer>
                </dd>
            </dl>
        </div>
    </div>
</template>

<style scoped>

</style>
